import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Material UI Components
import { Alert } from "@material-ui/lab";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Local
import { useDispatch } from "react-redux";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import CustomTable from "../components/Table";
import RightDrawer from "../components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import TrainingDetails from "./TrainingDetails";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import moment from "moment";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  item: {
    textAlign: "end",
  },
  button: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
    color: "white",
    border: "none",
    width: "15%",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  container: {
    float: "right",
  },
}));

const ViewTraining = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTraining, setCurrentTraining] = useState("");
  const [currentRef, setCurrentRef] = useState("");
  const [trainingDescription, setTrainingDescription] = useState("");
  const [recordCount, setrecordCount] = useState(10);
  const [searchValue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [rowstemp, setrowstemp] = useState([]); 
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [notificationType, setNotificationType] = useState("");
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - View Trainings":"View Trainings"));
    renderrow(pageNo,recordCount,searchValue);
  }, [pageNo,recordCount,searchValue]);
  // States
  const columns = [
    {
      Header: "Name",
      accessor: "training_name",
    },
    {
      Header: "Start Time",
      accessor: "start_time",
    },
    {
      Header: "End Time",
      accessor: "end_time",
    },
    {
      Header: "Time Zone",
      accessor: "time_zone",
    },
    {
      Header: "Training ID",
      accessor: "deal_id",
    },
    {
      Header: "Created On",
      accessor: "created_on",
    },
    {
      Header: "Users",
      accessor: "users",
    },
  ];

  let data = [];
  const renderrow = async (page_no,record_count,search_value) => {
    return SDCloudBackendAPIWithToken()
      .get(
        `/instructer/view_trainings?search=${search_value ??
          searchValue}&page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}`
      )
      .then((resp) => resp.data)
      .then((resp) => {
        resp.trainings.forEach((item) => {
          data.push({
            training_name: item.training_name,
            start_time: item.training_start_date,
            end_time: item.training_end_date,
            time_zone: item.time_zone,
            deal_id: item.deal_id,
            description: item.description,
            created_on: item.created_on,
            users: (
              <IconButton onClick={() => handleViewButton(item.training_name, item.reference_id, item.description)}>
                <Visibility />
              </IconButton>
            ),
          });
        });
        setrows(data);
        setIsLoading(false);
        setrowstemp(resp);
      })
      .catch((err) => {
        let message = err.response.data.message || "unknown error occured";
        setIsLoading(false);
      });
  };

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    let payload = {};
    selectedFlatRows.forEach((item) => {
      let training_name = item.original.training_name;
      payload["training_name"] = training_name;
      let reference_id = item.original.deal_id.split("-")[1];
      payload["reference_id"] = reference_id;
      SDCloudBackendAPIWithToken()
        .post(`/instructer/delete_trainings`,payload)
        .then((resp) => resp.data)
        .then((data) => {
          const payload = {
            message: "Training(s) Deleted Successfully.",
            color: "success",
          };
          dispatch(SHOW_NOTIFICATION({ payload }));
         renderrow(pageNo,recordCount,searchValue);
        })
        .catch((err) => {
          const payload = {
            message: err?.response?.data?.message || "Unable to delete training",
            color: "danger",
            error: err
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
        });
    });
  };
  const handleViewButton = (training_name, reference_id, description) => {
    setOpenDrawer(true);
    setCurrentScreen("Training Details");
    setCurrentTraining(training_name);
    setCurrentRef(reference_id);
    setTrainingDescription(description);
  };

  const renderNotififications = () => {
    let notifications = notificationMessage.map((item) => {
      let notificationType = item.success ? "success" : "error";
      return (
        <Alert
          onClose={() => setShowNotification(false)}
          severity={notificationType}
          spacing={1}
        >
          {item.message}
        </Alert>
      );
    });

    return notifications;
  };

  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "Training Details":
        let props={training_name:currentTraining, reference_id:currentRef, description:trainingDescription}
        return <TrainingDetails {...props} />;
      case "default":
        return null;
    }
  };

  const handleAddTrainingButton = () => {
    history.push("/Instructer/addtraining");  
  };
  const handleDataSearch = (search_value) => {
    setsearchvalue(search_value);
  };
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
  };
  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? renderNotififications() : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              handleDeleteButtonClick={handleDeleteButton}
              handleButtonClick={handleAddTrainingButton}
              handleViewButtonClick={handleViewButton}
              tableTitle="View Trainings"
              buttonTitle="Add New Training"
              hiddenColumns={[]}
              count={rowstemp?.pagination?.total_pages}
              page={rowstemp?.pagination?.current_page}
              setList={handlePagination}
              handleSearch={handleDataSearch}
            />
          </LoaderHoc>
        </Grid>
      </Grid>

      {/* Drawer */}
      <RightDrawer
        open={openDrawer}
        onClose={setOpenDrawer}
        title={currentScreen}
      >
        {renderDrawerComponent()}
      </RightDrawer>
    </div>
  );
};

export default ViewTraining;
