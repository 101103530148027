import React, { useEffect, useState } from "react";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Icons
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import Visibility from "@material-ui/icons/Visibility";

// Local
import CustomTable from "./Table";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import RightDrawer from "./RightDrawer";
import UserDetails from "./UserDetails";
import { Add } from "@material-ui/icons";
import CreateProfile from "./CreateProfile";
import ProfileDetails from "./ProfileDetails";

// Styling
const useStyles = makeStyles(theme => ({
  root: {
    // padding: "30px 20px",
  },
  starBorderIcon: {
    cursor: "pointer",
    "&:hover": {
      fill: theme.palette.secondary.main
    }
  },
  defaultProfile: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  }
}));

const PermissionProfile = () => {
  const classes = useStyles();
  // States
  const [rows, setRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentProfile, setCurrentProfile] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [learningPerms, setLearningPerms] = useState(null);
  const [sandboxPerms, setSandboxPerms] = useState(null);
  const [maxTemplates, setMaxTemplates] = useState(10);
  const [recordCount, setrecordCount] = useState(10);
  const [searchValue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [rowstemp, setrowstemp] = useState([]); 
  // useEffects
  useEffect(() => {
    renderData(pageNo,recordCount,searchValue);
  }, [pageNo,recordCount,searchValue]);

  const columns = [
    {
      Header: "Profile Name",
      accessor: "profile_name"
    },
    {
      Header: "",
      accessor: "default_profile"
    },
    {
      Header: "Actions",
      accessor: "actions"
    }
  ];

  let data = [];

  const handleDefaultProfileClick = profile_name => {
    if (
      window.confirm(
        `Are you sure you want to make ${profile_name} as default profile?`
      )
    ) {
      let payload = {
        profile_name: profile_name
      };

      let response = SDCloudBackendAPIWithToken()
        .post("/make_default_profile", payload)
        .then(resp => resp.data)
        .then(data => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Profile sucessfully marked as default");
        })
        .catch(err => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    }
  };

  const renderData = async (page_no,record_count,search_value) => {
    let response = SDCloudBackendAPIWithToken()
      .get(`/list_profiles?search=${search_value ??
          searchValue}&page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}`)
      .then(resp => resp.data)
      .then(resp => {
        let profiles = resp.profile_data;
        let default_profile_name = resp.default_profile;

        profiles.forEach(item => {
          data.push({
            profile_name: item.profile_name,
            default_profile:
              item.profile_name === default_profile_name ? (
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <StarIcon color="secondary"></StarIcon>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2" color="secondary">
                      Default Profile
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <StarBorderIcon
                      onClick={() =>
                        handleDefaultProfileClick(item.profile_name)
                      }
                      className={classes.starBorderIcon}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="body2"
                      className={classes.defaultProfile}
                      onClick={() =>
                        handleDefaultProfileClick(item.profile_name)
                      }
                    >
                      Mark as default profile
                    </Typography>
                  </Grid>
                </Grid>
              ),
            actions: (
              <IconButton>
                <Visibility
                  onClick={() => handleViewButton(item.profile_name)}
                  color="action"
                />
              </IconButton>
            )
          });
        });

        setRows(data);
        setIsLoading(false);
        setrowstemp(resp);
      });
  };

  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "Create Profile":
        return <CreateProfile />;
      case "Profile Details":
        if (currentProfile === null || profileData === null) {
          return null;
        } else if (currentProfile.profile_name !== profileData.profile_name) {
          return null;
        } else {
          return (
            <ProfileDetails
              profile={currentProfile}
              learningPerms={learningPerms}
              sandboxPerms={sandboxPerms}
              profileData={profileData}
              maxTemplates={maxTemplates}
            />
          );
        }
      case "default":
        return null;
    }
  };

  const handleCreateProfileButton = () => {
    setOpenDrawer(true);
    setCurrentScreen("Create Profile");
  };

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    let successMessages = [];
    let errorMessages = [];
    selectedFlatRows.forEach(item => {
      let payload = {};
      payload["profile_name"] = item.values.profile_name;
      SDCloudBackendAPIWithToken()
        .post("/delete_profile", payload)
        .then(resp => resp.data)
        .then(data => {
          successMessages.push(data);
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Profile deleted sucessfully");
          renderData(pageNo,recordCount,searchValue);
        })
        .catch(err => {
          let message = err.response.data.message;
          errorMessages.push(message);
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    });


    // if (successMessages.length !== 0 && errorMessages.length !== 0) {
    //   setShowNotification(true);
    //   setNotificationType("warning");
    //   setNotificationMessage("Partially deleted profiles");
    // } else if (successMessages.length !== 0) {
    //   setShowNotification(true);
    //   setNotificationType("success");
    //   setNotificationMessage("Profile deleted sucessfully");
    // } else if (errorMessages.length !== 0) {
    //   setShowNotification(true);
    //   setNotificationType("error");
    //   setNotificationMessage(errorMessages[0]);
    // }
  };

  const handleViewButton = profile_name => {
    let profileData = SDCloudBackendAPIWithToken()
      .post("/get_profile", {
        profile_name: profile_name
      })
      .then(resp => resp.data)
      .then(data => {
        let profiles = data.profiles[0];
        let max_allowed_templates = data.profiles[0].max_allowed_templates
        setMaxTemplates(max_allowed_templates);
        let payload = {
          profile_name: profile_name,
          learningPermissions: [],
          learningViews: [],
          sandboxPermissions: [],
          sandboxViews: [],
          designerPermissions: []
        };

        profiles.permissions.forEach(item => {
          if (item.resource_type === "learning" && item.permission === "view") {
            payload.learningViews.push(item.resource_name);
          } else if (
            item.resource_type === "learning" &&
            item.permission === "create"
          ) {
            payload.learningPermissions.push(item.resource_name);
          } else if (
            item.resource_type === "sandbox" &&
            item.permission === "view"
          ) {
            payload.sandboxViews.push(item.resource_name);
          } else if (
            item.resource_type === "sandbox" &&
            item.permission === "create"
          ) {
            payload.sandboxPermissions.push(item.resource_name);

            if (item.resource_name === "designer_portal") {
              if (item.designer_objects) {
                payload.designerPermissions = item.designer_objects.map(
                  designerObject => {
                    return designerObject.name;
                  }
                );
              }
            }
          }
        });

        let learn = [...payload.learningPermissions];
        let sand = [...payload.sandboxPermissions];
        setLearningPerms(learn);
        setSandboxPerms(sand);
        setOpenDrawer(true);
        setCurrentScreen("Profile Details");
        setCurrentProfile({ ...currentProfile, ...payload });
        setProfileData({ ...profileData, ...profiles });
      });
  };
  const handleDataSearch = (search_value) => {
    setsearchvalue(search_value);
  };
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
  };
  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
            >
              {notificationMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              handleDeleteButtonClick={handleDeleteButton}
              handleButtonClick={handleCreateProfileButton}
              handleViewButtonClick={handleViewButton}
              tableTitle="Permission Profiles"
              buttonTitle="Create Profile"
              dropDownValues={[]}
              count={rowstemp?.pagination?.total_pages}
              page={rowstemp?.pagination?.current_page}
              setList={handlePagination}
              handleSearch={handleDataSearch}
            />
          </LoaderHoc>
        </Grid>
      </Grid>

      {/* Drawer */}
      <RightDrawer
        open={openDrawer}
        onClose={setOpenDrawer}
        title={currentScreen}
      >
        {renderDrawerComponent()}
      </RightDrawer>
    </div>
  );
};

export default PermissionProfile;
