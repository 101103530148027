import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Formik
import { useFormi, useFormik } from "formik";

// Material UI Components
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
// Local
import ProfileTable from "./ProfileTable";
import LoaderHoc from "components/HOC/LoaderHoc";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: "0 40px"
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px"
    }
  },
  formControl: {
    width: "100%",
    marginTop: "5px"
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none"
    }
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`
    }
  }
}));

const ProfileDetails = props => {
  const classes = useStyles();
  

  // States
  const [showNotification, setShowNotification] = useState(false); 
  const [notificationMessage, setNotificationMessage] = useState(""); 
  const [notificationType, setNotificationType] = useState(""); 
  const [learningsData, setLearningsData] = useState([]); 
  const [showcasesData, setShowcasesData] = useState([]); 
  const [sandboxesData, setSandboxesData] = useState([]); 
  const [designerData, setDesignerData] = useState([]); 
  const [maxTemplates, setMaxTemplates] = useState(props.maxTemplates);
  var initialLearnings = props.learningPerms;
  var initialSandboxes = props.sandboxPerms;

  const [designer, setDesigner] = useState([]);
  const [enabledLearnings, setEnabledLearnings] = useState(
    props.profile.learningPermissions
  );
  const [disabledLearnings, setDisabledLearnings] = useState([]);
  const [enabledSandboxes, setEnabledSandboxes] = useState(
    props.profile.sandboxPermissions
  );
  const [disabledSandboxes, setDisabledSandboxes] = useState([]);
  const [resourceData, setResourceData] = useState({});
  const [initialState, setInitialState] = useState({ ...props.profile });
  const [temp, setTemp] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [allocValue, setAllocValue] = useState(false)

  useEffect(() => {
    setInitialState({ ...initialState, ...props.profile });
    setTemp(!temp);
    renderTemplateData(compare);
  }, [props.profile, props.profileData]);

  const columns = [
    {
      Header: "Template Name",
      accessor: "template_name",
      // maxWidth: 360,
      // minWidth: 360,
      // width: 360
    },
    {
      Header: "Name",
      accessor: "display_name",
      // maxWidth: 360,
      // minWidth: 360,
      // width: 360
    },
    // {
    //   Header: "Can View",
    //   accessor: "can_view",
    // },
    {
      Header: "Permission",
      accessor: "permission"
    },
    // {
    //   Header: "Hours Available",
    //   accessor: "hours_available"
    // },
    {
      Header: "Lab Hours",
      accessor: "lab_hours"
    },
    {
      Header: "Hours Consumed",
      accessor: "hours_consumed"
    },
    {
      Header: "Allocate Hours",
      accessor: "allocate_hours"
    },
    {
      Header: "Max Templates",
      accessor: "max_allowed_templates"
    }

  ];

  const designerColumns = [
    {
      Header: "Template Name",
      accessor: "template_name"
    },
    {
      Header: "Name",
      accessor: "display_name"
    },
    {
      Header: "Permission",
      accessor: "permission"
    }
  ];

  const handleToggleChange = (value, type) => {
    if (type == "learn") {
      if (enabledLearnings.includes(value)) {
        let updatedLearnings = enabledLearnings.filter(function(
          name,
          index,
          arr
        ) {
          return name != value;
        });
        setEnabledLearnings([...updatedLearnings]);
        if (initialLearnings.includes(value)) {
          let deleteLearnings = disabledLearnings;
          deleteLearnings.push(value);
          setDisabledLearnings(deleteLearnings);
        }
      } else {
        let deleteLearnings = disabledLearnings.filter(function(
          name,
          index,
          arr
        ) {
          return name != value;
        });
        setDisabledLearnings(deleteLearnings);
        let updatedLearnings = enabledLearnings;
        updatedLearnings.push(value);
        setEnabledLearnings([...updatedLearnings]);
      }
    }
    if (type == "sandbox") {
      if (enabledSandboxes.includes(value)) {
        let updatedSandboxes = enabledSandboxes.filter(function(
          name,
          index,
          arr
        ) {
          return name != value;
        });
        setEnabledSandboxes([...updatedSandboxes]);
        if (initialSandboxes.includes(value)) {
          let deleteSandboxes = disabledSandboxes;
          deleteSandboxes.push(value);
          setDisabledSandboxes(deleteSandboxes);
        }
      } else {
        let deleteSandboxes = disabledSandboxes.filter(function(
          name,
          index,
          arr
        ) {
          return name != value;
        });
        setDisabledSandboxes(deleteSandboxes);
        let updatedSandboxes = enabledSandboxes;
        updatedSandboxes.push(value);
        setEnabledSandboxes([...updatedSandboxes]);
      }
    }
  };
 
  const formik = useFormik({
    initialValues: { ...initialState },
    enableReinitialize: true,
    onSubmit: values => {
      let permissions = [];
      let deletePermissions = [];
      let max_allowed_templates = 0
      // values.learningViews.forEach((item) => {
      //   let payload = {
      //     resource_type: "Learning",
      //     resource_name: item,
      //     permission: "view",
      //     alloc_hours: 0,
      //   };
      //   if (!values.learningPermissions.includes(item)) {
      //     permissions.push(payload);
      //   }
      // });

      enabledLearnings.forEach(item => {
        try {
          let payload = {
            resource_type: "Learning",
            resource_name: item,
            permission: "create",
            alloc_hours:
              document.getElementById(`${item}_allocate_hours`).value || 0
          };
          permissions.push(payload);
        } catch (err) {
          //console.log("");
        }
      });
      disabledLearnings.forEach(item => {
        try {
          let payload = {
            resource_type: "Learning",
            resource_name: item
          };
          deletePermissions.push(payload);
        } catch (err) {
          //console.log("");
        }
      });

      // values.sandboxViews.forEach((item) => {
      //   let payload = {
      //     resource_type: "Sandbox",
      //     resource_name: item,
      //     permission: "view",
      //     alloc_hours: document.getElementById(`${item}_allocate_hours`).value,
      //   };
      //   if (!values.sandboxPermissions.includes(item)) {
      //     permissions.push(payload);
      //   }
      // });

      enabledSandboxes.forEach(item => {
        try {
          let payload = {
            resource_type: "Sandbox",
            resource_name: item,
            permission: "create",
            alloc_hours:
              document.getElementById(`${item}_allocate_hours`).value || 0
          };

          if (item === "designer_portal") {
            payload["designer_objects"] = values.designerPermissions;
            max_allowed_templates = document.getElementById(`${item}_max_allowed_templates`).value || 0
          }

          permissions.push(payload);
        } catch (err) {
          //console.log("");
        }
      });
      disabledSandboxes.forEach(item => {
        try {
          let payload = {
            resource_type: "Sandbox",
            resource_name: item
          };

          deletePermissions.push(payload);
        } catch (err) {
          //console.log("");
        }
      });

      let payload = {
        profile_name: props.profile.profile_name,
        permissions: permissions,
        delete_permissions: deletePermissions,
        delete: deletePermissions.length > 0 ? true : false,
        max_allowed_templates: max_allowed_templates
      };

      SDCloudBackendAPIWithToken()
        .post("/update_profile", payload)
        .then(resp => resp.data)
        .then(data => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Profile updated successfully");
          initialLearnings = props.learningPerms;
          initialSandboxes = props.sandboxPerms;
          setTimeout(function () {
            window.location.reload();
        }, 2000);
        })
        .catch(err => {
          let supportInfo = (
              <p>
                {err.response.data.message}. Visit our{" "}
                <a
                    href="https://criterion.zendesk.com/hc/en-us"
                    target="_blank"
                >
                  help center
                </a>{" "}
                for resources and support.
              </p>
          )
          // let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(supportInfo);
        });
    }
  });
  const compare = (e, consumed_hours_value) => {
    if (Number(e.target.value) < Number(consumed_hours_value)) {
      setAllocValue(true);
      setNotificationType("error");
      setNotificationMessage(
        "Allocated hours should be greater than Consumed hours"
      );
    } else {
      setAllocValue(false);
    }
  };
  const renderTemplateData = async (compare) => {
    let data = {};

    if (Object.keys(resourceData).length === 0) {
      const response = await SDCloudBackendAPIWithToken().get("/get_resources");
      data = response.data;
      setResourceData(data);
    } else {
      data = resourceData;
    }

    let learnings = [];
    let showcases = [];
    let sandboxes = [];
    let designer = [];
    let designerObjects = [];

    // Learnings & Showcases
    data.learnings.forEach(item => {
      let allocated_hours_value = null;
      let consumed_hours_value = 0;
      if (props.profileData) {
        props.profileData.permissions.find(a => {
          if (a.resource_name === item.name) {
            allocated_hours_value = a.allocated_hours;
            consumed_hours_value = parseFloat(a.consumed_hours).toFixed(2);
          }
        });
      }

      if (item.is_scripted_demo) {
        showcases.push({
          template_name: item.name,
          display_name: item.display_name,
          can_view: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name="learningViews"
                  color="default"
                />
              }
              onChange={formik.handleChange}
              // onChange={e => {
              //   handleToggleChange(e.target.value);
              // }}
              checked={
                formik.values.learningViews.includes(item.name) ? true : false
              }
            />
          ),
          permission: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name="learningPermissions"
                  color="default"
                />
              }
              // onChange={formik.handleChange}
              onChange={e => {
                handleToggleChange(e.target.value, "learn");
              }}
              checked={enabledLearnings.includes(item.name) ? true : false}
            />
          ),
          // hours_available:
          //   item.allocated_hours - item.consumed_hours > 0
          //     ? item.allocated_hours - item.consumed_hours
          //     : 0,
          hours_consumed: consumed_hours_value,
          lab_hours: item.labhours,
          allocate_hours: (
            <TextField
              type="number"
              variant="filled"
              name={`${item.name}_allocate_hours`}
              fullWidth
              onChange={(e) => {
                  compare(e, consumed_hours_value);
                }}
              inputProps={{ id: `${item.name}_allocate_hours` }}
              InputLabelProps={inputProps}
              defaultValue={allocated_hours_value}
            />
          )
        });
      } else {
        learnings.push({
          template_name: item.name,
          display_name: item.display_name,
          can_view: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name="learningViews"
                  color="default"
                />
              }
              onChange={formik.handleChange}
              checked={
                formik.values.learningViews.includes(item.name) ? true : false
              }
            />
          ),
          permission: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name="learningPermissions"
                  color="default"
                />
              }
              // onChange={formik.handleChange}
              onChange={e => {
                handleToggleChange(e.target.value, "learn");
              }}
              checked={enabledLearnings.includes(item.name) ? true : false}
            />
          ),
          // hours_available:
          //   item.allocated_hours - item.consumed_hours > 0
          //     ? item.allocated_hours - item.consumed_hours
          //     : 0,
          hours_consumed: consumed_hours_value,
          lab_hours: item.labhours,
          allocate_hours: (
            <TextField
              type="number"
              variant="filled"
              name={`${item.name}_allocate_hours`}
              fullWidth
              onChange={(e) => {
                  compare(e, consumed_hours_value);
                }}
              inputProps={{ id: `${item.name}_allocate_hours` }}
              InputLabelProps={inputProps}
              defaultValue={allocated_hours_value}
            />
          )
        });
      }
    });

    // Sandboxes & Designer
    data.sandboxes.forEach(item => {
      let allocated_hours_value = null;
      let consumed_hours_value = 0;
      if (props.profileData) {
        props.profileData.permissions.find(a => {
          if (a.resource_name === item.name) {
            allocated_hours_value = a.allocated_hours;
            consumed_hours_value = parseFloat(a.consumed_hours).toFixed(2); 
          }
        });
      }
      if (item.name === "designer_portal") {
        designerObjects = item.designer_objects.map(designerObject => ({
          template_name: designerObject.name,
          display_name: designerObject.display_name,
          permission: (
            <FormControlLabel
              control={
                <Switch
                  value={designerObject.name}
                  name={`designerPermissions`}
                  color="default"
                />
              }
              onChange={formik.handleChange}
            />
          )
        }));

        designer.push({
          template_name: item.name,
          display_name: "Designer",
          can_view: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name={`sandboxViews`}
                  color="default"
                />
              }
              onChange={formik.handleChange}
              checked={
                formik.values.sandboxViews.includes(item.name) ? true : false
              }
            />
          ),
          permission: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name={`sandboxPermissions`}
                  color="default"
                />
              }
              // onChange={formik.handleChange}
              onChange={e => {
                handleToggleChange(e.target.value, "sandbox");
              }}
              checked={enabledSandboxes.includes(item.name) ? true : false}
            />
          ),
          // hours_available:
          //   item.allocated_hours - item.consumed_hours > 0
          //     ? item.allocated_hours - item.consumed_hours
          //     : 0,
          hours_consumed: consumed_hours_value,
          lab_hours: item.labhours,
          allocate_hours: (
            <TextField
              type="number"
              variant="filled"
              name={`${item.name}_allocate_hours`}
              fullWidth
              InputProps={{ id: `${item.name}_allocate_hours` }}
              InputLabelProps={inputProps}
              defaultValue={allocated_hours_value}
              style={{paddingRight:"15px"}}
            />
          ),
          max_allowed_templates: (
            <TextField
              type="number"
              variant="filled"
              name={`${item.name}_max_allowed_templates`}
              fullWidth
              InputProps={{ id: `${item.name}_max_allowed_templates` }}
              InputLabelProps={inputProps}
              defaultValue={maxTemplates}
              style={{paddingRight:"15px"}}
            />
          )
        });

        setDesigner(designerObjects);
      } else {
        sandboxes.push({
          template_name: item.name,
          display_name:
            item.name === "designer_portal" ? "Designer" : item.display_name,
          can_view: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name={`sandboxViews`}
                  color="default"
                />
              }
              onChange={formik.handleChange}
              checked={
                formik.values.sandboxViews.includes(item.name) ? true : false
              }
            />
          ),
          permission: (
            <FormControlLabel
              control={
                <Switch
                  value={item.name}
                  name={`sandboxPermissions`}
                  color="default"
                />
              }
              // onChange={formik.handleChange}
              onChange={e => {
                handleToggleChange(e.target.value, "sandbox");
              }}
              checked={enabledSandboxes.includes(item.name) ? true : false}
            />
          ),
          // hours_available:
          //   item.allocated_hours - item.consumed_hours > 0
          //     ? item.allocated_hours - item.consumed_hours
          //     : 0,
          hours_consumed: consumed_hours_value,
          lab_hours: item.labhours,
          allocate_hours: (
            <TextField
              type="number"
              variant="filled"
              name={`${item.name}_allocate_hours`}
              fullWidth
              onChange={(e) => {
                  compare(e, consumed_hours_value);
                }}
              InputProps={{ id: `${item.name}_allocate_hours` }}
              InputLabelProps={inputProps}
              defaultValue={allocated_hours_value}
            />
          )
        });
      }
    });

    setLearningsData(learnings);
    setShowcasesData(showcases);
    setSandboxesData(sandboxes);
    setDesignerData(designer);
    setShowLoader(false);
  };

  useEffect(() => {
    renderTemplateData(compare);
  }, [
    formik.values,
    enabledLearnings,
    enabledSandboxes,
    disabledLearnings,
    disabledSandboxes,
    allocValue
  ]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <Typography variant="h4">{props.profile.profile_name}</Typography>
          </Grid>

          {showLoader ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "80px"
              }}
            >
              <LoaderHoc isLoading={showLoader}></LoaderHoc>
            </div>
          ) : (
            <>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={learningsData}
                    tableTitle="Learning Center"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name", 
                      // "hours_available",
                      "max_allowed_templates"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={showcasesData}
                    tableTitle="Experience Center - Showcases"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={["template_name", 
                      // "hours_available", 
                        "max_allowed_templates"]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={sandboxesData}
                    tableTitle="Experience Center - Sandboxes"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={[
                      "template_name",
                      "lab_hours",
                      // "hours_available",
                      "max_allowed_templates"
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
                <Grid container justify="space-between">
                  <ProfileTable
                    columns={columns}
                    data={designerData}
                    tableTitle="Design Center"
                    buttonTitle="Create Profile"
                    dropDownValues={[]}
                    hiddenColumns={[
                      "template_name",
                      "lab_hours",
                      // "hours_available"
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  className={classes.buttOutlined}
                  disableFocusRipple
                  type="submit"
                  disabled = {allocValue ? true : false}
                >
                  Submit
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12}>
            {allocValue ? (
              <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            ) : null}
          </Grid>    
          <Grid item xs={12} md={12}>
            {showNotification ? (
              <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            ) : null}
          </Grid>
        </Grid>

        {/* </Grid> */}
      </form>
    </div>
  );
};

export default ProfileDetails;
