import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import SvgtoIcon from "utils/SvgtoIcon";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AttachUserModal from "./AttachUserModal";
import DeleteStaticIpModal from "./IpConfirmationDialog.js";
import CustomSnackbar from "./CustomSnackbar.js";
import { ReactComponent as deleteSvg } from "assets/criterion_networks/svg/delete.svg";
import { Alert } from "@material-ui/lab";

// Local
import IpCustomTable from "./IpInformationTable";
import { useDispatch } from "react-redux";
import AddStaticIp from "./AddStaticIp.js";
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import { Api } from "config/api";
import LoaderHoc from "../../components/HOC/LoaderHoc";
import { saveAs } from "file-saver";
import "./accounts.css";

const useStyles = makeStyles(theme => ({
  alertStyle: {
    borderColor: theme.palette.buttonColor.main,
    color: theme.palette.buttonColor.main,
    "&:hover": {
      borderColor: theme.palette.buttonColor.main
    }
  }
}));

function IpInformation(props) {
  // States
  const [rows, setRows] = useState([]);
  const [hideModal, setHideModal] = useState(true);
  const [hideForm, setHideForm] = useState(true);
  const [hideTable, setHideTable] = useState(true);
  const [hideDeleteModal, setHideDeleteModal] = useState(true);
  const [currentUser, setCurrentUser] = useState("");
  const [currentIp, setCurrentIp] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentAction, setCurrentAction] = useState("");
  const [tableOpacity, setTableOpacity] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [recordCount, setrecordCount] = useState(10);
  const [pageNo, setpageNo] = useState(1);
  const [rowstemp, setrowstemp] = useState([]);
  

  const classes = useStyles();

  useEffect(() => {
    renderData(pageNo,recordCount);
  }, [pageNo,recordCount]);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      disableGroupBy: true
    },
    {
      Header: "IP information",
      accessor: "ipInfo",
      disableGroupBy: true
    },
    {
      Header: "Username",
      accessor: "user",
      disableGroupBy: true
    },
    {
      Header: "Last updated",
      accessor: "lastUpdated",
      disableGroupBy: true
    },
    {
      Header: "Action",
      accessor: "action",
      disableGroupBy: true
    }
  ];

  const deleteConfirmAlert = () => {
    setDeleteAlert();
    setTimeout(() => {
      setDeleteAlert(
        <CustomSnackbar
          place="tr"
          color="success"
          icon={CheckCircleIcon}
          autoHideDuration={5000}
          message={`Ip deleted successfully`}
          open={true}
          closeNotification={() => hideDeleteAlert()}
          close
        />
      );
    }, 7);
  };

  const hideDeleteAlert = () => {
    setDeleteAlert(null);
  };

  const addConfirmAlert = () => {
    setAlert();
    setTimeout(() => {
      setAlert(
        <CustomSnackbar
          place="tr"
          color="success"
          icon={CheckCircleIcon}
          autoHideDuration={5000}
          message={`Ip created successfully`}
          open={true}
          closeNotification={() => hideAlert()}
          close
        />
      );
    }, 7);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const renderData = (page_no,record_count) => {
    let data = [];
    let response = SDCloudBackendAPIWithToken()
      .get(`/staticip/get_static_ip?page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}`)
      .then(resp => {
        resp.data.ip_info.forEach((item, index) => {
          data.push({
            // slNo: index + 1,
            name: item.name,
            ipInfo: item.ip,
            user: item.user ? item.user : "None",
            lastUpdated: new Date(item.last_updated).toLocaleString(),
            action: (
              <div>
                <Grid container wrap="wrap" spacing={1}>
                  <Grid item>
                    <IconButton
                      onClick={() => attachDetachUser(item.user, item.ip)}
                      title="Update user"
                    >
                      {item.user ? <LinkOffIcon /> : <AttachmentIcon />}
                    </IconButton>
                  </Grid>

                  <Grid item className="delete-icon">
                    <IconButton
                      // className={classes.deleteIcon}
                      onClick={() => handleDeleteButton(item.name, item.ip)}
                      title="Delete"
                    >
                      <SvgtoIcon svgSource={deleteSvg} />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            )
          });
        });

        data.sort((a, b) => {
          if (a.lastUpdated > b.lastUpdated) return -1;
          if (a.lastUpdated < b.lastUpdated) return 1;
          return 0;
        });
        setRows(data);
        setHideTable(false);
        setIsLoading(false);
        setrowstemp(resp.data);
      })
      .catch(err => {
        let message = err.response.data.message;
        setRows([]);
        setIsLoading(false);
        setHideTable(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });
  };

  const attachDetachUser = (user, ip) => {
    let payload = {};
    if (!user) {
      setCurrentIp(ip);
      setCurrentAction("attach");
      setHideModal(false);
    } else {
      setTableOpacity(0.3);
      payload = {
        ip: ip,
        action: "detach"
      };
      SDCloudBackendAPIWithToken()
        .put(Api.updateStaticIp, payload)
        .then(resp => resp.data)
        .then(data => {
          renderData(pageNo,recordCount)
          setTableOpacity(1);
        })
        .catch(err => {
          setTableOpacity(1);
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    }
  };

  const handleNavigate = () => {
    setHideForm(false);
    setHideTable(true);
  };

  const handleCancelStaticIp = () => {
    setHideForm(true);
    setHideTable(false);
  };

  const handleAddIpButton = () => {
    setHideForm(true);
    setHideTable(false);
    setTableOpacity(1);
  };

  const handleDeleteButton = (name, ip) => {
    setCurrentIp(ip);
    setCurrentName(name);
    setHideDeleteModal(false);
    // .catch(err => {
    //   let message = err.response.data.message;
    //   setShowNotification(true);
    //   setNotificationType("error");
    //   setNotificationMessage(message);
    // });
  };

  const handleExcel = () => {
    setTableOpacity(0.3);
    SDCloudBackendAPIWithToken()
      .get(Api.downloadIpDetails, { responseType: "blob" })
      .then(resp => {
        var blob = new Blob([resp.data], {
          type: "application/ms-excel;charset=utf-8"
        });
        saveAs(blob, `${"IPDetails"}.xls`);
        setTableOpacity(1);
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true
        };
        setTableOpacity(1);
      });
  };

  // useEffect(() => {
  //   let available_purposes = JSON.parse(
  //     localStorage.getItem("available_purposes")
  //   );
  //   if (isInternal) {
  //     if (available_purposes?.internal) {
  //       let purposes = available_purposes.internal.map(item => {
  //         return <option value={item}>{item}</option>;
  //       });
  //       setMenuItemsVals([<option value={null}>None</option>, ...purposes]);
  //     }
  //   } else {
  //     if (available_purposes?.external) {
  //       let purposes = available_purposes.external.map(item => {
  //         return <option value={item}>{item}</option>;
  //       });
  //       setMenuItemsVals([<option value={null}>None</option>, ...purposes]);
  //     }
  //   }

  //   if (props.reservedIP.hasOwnProperty("reservedIps")) {
  //     let ipAddresses = props.reservedIP.reservedIps.map(item => {
  //       return <option value={item}>{item}</option>;
  //     });
  //     setMenuItems([<option value={null}>None</option>, ...ipAddresses]);
  //   }
  // }, []);
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
  };
  return (
    <div>
      {alert}
      {deleteAlert}
      <Grid container>
        {hideModal ? null : (
          <AttachUserModal
            openModal={hideModal}
            hideModal={setHideModal}
            renderData={renderData}
            initialValues={{
              userName: currentUser
            }}
            ip={currentIp}
            action={currentAction}
          />
        )}
        {hideDeleteModal ? null : (
          <DeleteStaticIpModal
            openModal={hideDeleteModal}
            hideModal={setHideDeleteModal}
            renderData={renderData}
            ip={currentIp}
            name={currentName}
            deleteConfirmAlert={deleteConfirmAlert}
          />
        )}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "2%", opacity: tableOpacity }}
        >
          {showNotification ? (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
            >
              {notificationMessage}
            </Alert>
          ) : null}
          <LoaderHoc isLoading={isLoading}>
            {hideForm ? null : (
              <AddStaticIp
                renderData={renderData}
                handleAddIpButton={handleAddIpButton}
                setTableOpacity={setTableOpacity}
                handleCancelStaticIp={handleCancelStaticIp}
                addConfirmAlert={addConfirmAlert}
              />
            )}
            {hideTable ? null : (
              <IpCustomTable
                columns={columns}
                data={rows}
                // handleDeleteButtonClick={handleDeleteButton}
                // handleButtonClick={handleAddIpButton}
                //   handleViewButtonClick={handleViewButton}
                handleExcel={handleExcel}
                handleNavigate={handleNavigate}
                tableTitle="Static IP"
                buttonTitle="Add static IP"
                dropDownValues={[]}
                count={rowstemp?.pagination?.total_pages}
                page={rowstemp?.pagination?.current_page}
                setIpList={handlePagination}
              />
            )}
          </LoaderHoc>
        </Grid>
      </Grid>
    </div>
  );
}

export default IpInformation;
