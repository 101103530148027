import React, { useEffect, useState } from "react";
import { useHistory, browserHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";

// Local
import Menu from "../Components/Menu";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import { Api } from "config/api";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// Material UI Components
import { Alert } from "@material-ui/lab";
// Local
import CustomTable from "./inventoryTable";
import RightDrawer from "../Usermanagement/components/RightDrawer";
import LoaderHoc from "../../components/HOC/LoaderHoc";
import { IconButton, Button } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import ViewInventory from "./components/ViewInventory";
import AddInventory from "./components/AddInventory";
import AuditLogs from "./components/AuditLogs";
import Chatbot from "views/Components/chatbot/Chatbot";
import { Redirect, Route } from "react-router-dom";

// Styling
const useStyles = makeStyles((theme) => ({
  // root: {
  //   marginTop: "50px",
  // },
  backButton: {
    margin: "15px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      marginRight: "5px",
    },
    "&:hover": {
      color: theme.palette.secondary.main,
      "& svg": {
        fill: theme.palette.secondary.main,
      },
    },
  },
}));

const InventoryManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Inventory Management": "Inventory Management"));
    // renderrow();
  }, []);
  // States
  const [learningRows, setLearningRows] = useState([]);
  const [sandboxesRows, setSandboxesRows] = useState([]);
  const [showcasesRows, setShowcasesRows] = useState([]);
  const [designerRows, setDesignerRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentInventory, setCurrentInventory] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [currentDrawerScreen, setCurrentDrawerScreen] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [tableOpacity, setTableOpacity] = useState("");
  const [recordCount, setrecordCount] = useState(10);
  const [searchValue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [rowstemp, setrowstemp] = useState([]); 
  const columns = [
    {
      Header: "SKU ID",
      accessor: "sku_id",
    },
    {
      Header: "SKU Name",
      accessor: "sku_name",
    },
    {
      Header: "Display Name",
      accessor:"display_name",
    },
    {
      Header: "Account Name",
      accessor: "account",
    },
    {
      Header: "Created On",
      accessor: "created_on",
    },
    {
      Header: "View",
      accessor: "view",
    },
  ];

  const designer_columns = [
    {
      Header: "Icon",
      accessor: "icon",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Version",
      accessor:"version",
    },
    {
      Header: "Element State",
      accessor: "state",
    },
    {
      Header: "Created by",
      accessor: "created_by",
    },
    {
      Header: "Created at",
      accessor: "created_at",
    },
    {
      Header: "Audit Logs",
      accessor: "audit_logs",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  useEffect(() => {
    renderrow(pageNo,recordCount,searchValue,currentScreen);
  }, [pageNo,recordCount,searchValue,currentScreen]);


  let learningsData = [];
  let sandboxesData = [];
  let showcasesData = [];
  let designerData = [];
  var menuItems = ["Learnings", "Sandboxes", "Showcases", "Designer", "Custom Elements"];
  const [currentScreen, setCurrentScreen] = useState(menuItems[0]);
  const [auditLogs, setAuditLogs] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const renderNotififications = () => {
    let notifications = notificationMessage.map((item) => {
      let notificationType = item.success ? "success" : "error";
      return (
        <Alert
          onClose={() => setShowNotification(false)}
          severity={notificationType}
          spacing={1}
        >
          {item.message}
        </Alert>
      );
    });

    return notifications;
  };
  const renderDrawerComponent = () => {
    switch (currentDrawerScreen) {
      case "Inventory Details":
        let props = { sku_id: currentInventory, inv_type: currentScreen };
        return <ViewInventory {...props} />;
      case "Add New Inventory":
        props = { inv_type: currentScreen };
        return <AddInventory {...props} />;
      case "Audit Logs":
        props = { audit_logs: auditLogs };
        return <AuditLogs {...props} />;
      case "default":
        return null;
    }
  };
  const handleExcel = () => {
    setTableOpacity(0.3);
    SDCloudBackendAPIWithToken()
      .get(Api.downloadInventoryExcel, { responseType: "blob" })
      .then(resp => {
        var blob = new Blob([resp.data], {
          type: "application/ms-excel;charset=utf-8"
        });
        saveAs(blob, `${"InventroyDetails"}.xls`);
        setTableOpacity(1);
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true
        };
        setTableOpacity(1);
      });
  };

  const renderrow = async (page_no,record_count,search_value,screen) => {
    let inventory_type;
    if (window.location.href.indexOf("Showcases") > 1) {
      setCurrentScreen("Showcases");
    } else if (window.location.href.indexOf("Sandboxes") > 1) {
      setCurrentScreen("Sandboxes");
    } else if (window.location.href.indexOf("Designer") > 1) {
      setCurrentScreen("Designer");
    }
    if(screen){
      inventory_type = screen
    } else {
      inventory_type = "Learnings"
    }
    return SDCloudBackendAPIWithToken()
      .get(`/inventory/view_inventories/?search=${search_value ??
          searchValue}&page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}&inventory_type=${inventory_type}`)
      .then((resp) => resp.data)
      .then((resp) => {
        resp.inventories.forEach((item) => {
          if (item.inventory_type == "Learnings") {
            learningsData.push({
              sku_id: item.sku_id,
              sku_name: item.sku_name,
              type: item.inventory_type,
              state: item.inventory_state,
              env:item.inventory_env,
              created_on: item.created_at
              ? moment(item.created_at).format("MM/DD/YYYY hh:mm a")
              : "",
              account:JSON.stringify(item.account_name).replace(/[\[\]'\"]+/g,''),
              display_name:item.display_name,
              view: (
                <IconButton onClick={() => handleViewButton(item.sku_id)}>
                  <Visibility />
                </IconButton>
              ),
            });
          }
          if (item.inventory_type == "Sandboxes") {
            sandboxesData.push({
              sku_id: item.sku_id,
              sku_name: item.sku_name,
              type: item.inventory_type,
              state: item.inventory_state,
              env:item.inventory_env,
              created_on: item.created_at
              ? moment(item.created_at).format("MM/DD/YYYY hh:mm a")
              : "",
              account:JSON.stringify(item.account_name).replace(/[\[\]'\"]+/g,''),
              display_name:item.display_name,
              view: (
                <IconButton onClick={() => handleViewButton(item.sku_id)}>
                  <Visibility />
                </IconButton>
              ),
            });
          }
          if (item.inventory_type == "Showcases") {
            showcasesData.push({
              sku_id: item.sku_id,
              sku_name: item.sku_name,
              type: item.inventory_type,
              state: item.inventory_state,
              env:item.inventory_env,
              created_on: item.created_at
              ? moment(item.created_at).format("MM/DD/YYYY hh:mm a")
              : "",
              account:JSON.stringify(item.account_name).replace(/[\[\]'\"]+/g,''),
              display_name:item.display_name,
              view: (
                <IconButton onClick={() => handleViewButton(item.sku_id)}>
                  <Visibility />
                </IconButton>
              ),
            });
          }
        });
        resp.designer_inventories.forEach((item) => {
            designerData.push({
              icon: (<div className="display-icon">
              {item.icon.trim() !== "" ? (
                <img
                  className="img-fluid image-dim"
                  src={item.icon}
                  alt=""
                  style={{width:"40px",height:"40px"}}
                ></img>
              ) : (
                item.icon
              )}
            </div>),
              name: item.name,
              version: item.version,
              state: item.inventory_state,
              created_by:item.created_by,
              created_at: item.created_at
              ? moment(item.created_at).format("MM/DD/YYYY hh:mm a")
              : "",
              audit_logs:(<IconButton onClick={() => handleAuditViewButton(item.audit_logs)}>
                            <Visibility />
                        </IconButton>),
              action: (
                <IconButton onClick={() => handleViewButton(item.sku_id)}>
                  <Visibility />
                </IconButton>
              ),
            });
        });
        setLearningRows(learningsData);
        setSandboxesRows(sandboxesData);
        setShowcasesRows(showcasesData);
        setDesignerRows(designerData);
        setIsLoading(false);
        setrowstemp(resp);
      })
      .catch((err) => {
        const payload = {
          message: err.response.data.message,
          color: "danger"
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        setIsLoading(false);
      });
  };

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    let payload = {};
    selectedFlatRows.forEach((item) => {
      let sku_id = item.original.sku_id;
      payload["sku_id"] = sku_id;
      SDCloudBackendAPIWithToken()
        .post(`/inventory/delete_inventories`, payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          renderrow(pageNo,recordCount,searchValue,currentScreen);
        });
    });
  };
  const handleViewButton = (sku_id) => {
    setOpenDrawer(true);
    setCurrentDrawerScreen("Inventory Details");
    setCurrentInventory(sku_id);
  };

  const handleAuditViewButton = (audit_logs,sku_id) => {
    setOpenDrawer(true);
    setCurrentDrawerScreen("Audit Logs");
    setCurrentInventory(sku_id);
    setAuditLogs(audit_logs)
  };

  const handleAddInventoryButton = () => {
    setOpenDrawer(true);
    setCurrentDrawerScreen("Add New Inventory");
    setCurrentInventory("Add New Inventory");
  };
  // const history = useHistory();
  // const goToPreviousPath = (e) => {
  //   e.preventDefault();
  //   history.goBack();
  // };
  const handleDataSearch = (search_value) => {
    setsearchvalue(search_value);
  };
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
    renderrow(page_no,record_count,'',currentScreen)
  };
  const handleCurrentScreen = (event)=>{
    setCurrentScreen(event)
    renderrow(1,10,searchValue,event)
  }

  const renderContent = () => {
    switch (currentScreen) {
      case "Learnings":
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={14}>
              {showNotification ? renderNotififications() : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <LoaderHoc isLoading={isLoading}>
                <CustomTable
                  columns={columns}
                  data={learningRows}
                  handleDeleteButtonClick={handleDeleteButton}
                  handleButtonClick={handleAddInventoryButton}
                  handleViewButtonClick={handleViewButton}
                  tableTitle="Learnings"
                  buttonTitle="Add New"
                  hiddenColumns={[]}
                  count={rowstemp?.pagination?.total_pages}
                  page={rowstemp?.pagination?.current_page}
                  setList={handlePagination}
                  handleSearch={handleDataSearch}
                  screen={currentScreen}
                />
              </LoaderHoc>
            </Grid>
          </Grid>
        );
      case "Sandboxes":
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {showNotification ? renderNotififications() : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <LoaderHoc isLoading={isLoading}>
                <CustomTable
                  columns={columns}
                  data={sandboxesRows}
                  handleDeleteButtonClick={handleDeleteButton}
                  handleButtonClick={handleAddInventoryButton}
                  handleViewButtonClick={handleViewButton}
                  tableTitle="Sandboxes"
                  buttonTitle="Add New"
                  hiddenColumns={[]}
                  count={rowstemp?.pagination?.total_pages}
                  page={rowstemp?.pagination?.current_page}
                  setList={handlePagination}
                  handleSearch={handleDataSearch}
                  screen={currentScreen}
                />
              </LoaderHoc>
            </Grid>
          </Grid>
        );
      case "Showcases":
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {showNotification ? renderNotififications() : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <LoaderHoc isLoading={isLoading}>
                <CustomTable
                  columns={columns}
                  data={showcasesRows}
                  handleDeleteButtonClick={handleDeleteButton}
                  handleButtonClick={handleAddInventoryButton}
                  handleViewButtonClick={handleViewButton}
                  tableTitle="Showcases"
                  buttonTitle="Add New"
                  hiddenColumns={[]}
                  count={rowstemp?.pagination?.total_pages}
                  page={rowstemp?.pagination?.current_page}
                  setList={handlePagination}
                  handleSearch={handleDataSearch}
                  screen={currentScreen}
                />
              </LoaderHoc>
            </Grid>
          </Grid>
        );
      case "Designer":
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {showNotification ? renderNotififications() : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <LoaderHoc isLoading={isLoading}>
                <CustomTable
                        columns={designer_columns}
                        data={designerRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddInventoryButton}
                        handleViewButtonClick={handleViewButton}
                        tableTitle="Designer Element Inventory"
                        buttonTitle="Add New"
                        hiddenColumns={[]}
                        count={rowstemp?.pagination?.total_pages}
                        page={rowstemp?.pagination?.current_page}
                        setList={handlePagination}
                        handleSearch={handleDataSearch}
                        screen={currentScreen}
                      />
              </LoaderHoc>
            </Grid>
          </Grid>
        );
      
        case "Custom Elements":
            return (
              <Route>
                <Redirect to="/custom-elements"></Redirect>
              </Route>
            );
    }
  };
  const history = useHistory();
  const backButton = () => {
    history.push("/contentmanagement");
  };
  return (
    <div>
      <GridContainer className="backbutton">
        <GridItem sm={12} md={12}>
          <Typography
            variant="body1"
            className={classes.backButton}
            onClick={backButton}
          >
            <ArrowBackIcon /> Back
          </Typography>
        </GridItem>
      </GridContainer>
      <Grid container alignItems="center" justify="flex-end">
            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleExcel}
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                disableFocusRipple
                className={classes.btnColor}
                type="submit"
                style={{ height: "40px", marginRight: "10px" }}
              >
                Export Inventory to Excel Sheet
              </Button>
            </Grid>
            </Grid>  
      <Grid container className={classes.root}>
        <Grid item md={2}>
          <Menu
            items={menuItems}
            onItemClick={handleCurrentScreen}
            currentScreen={currentScreen}
          />
        </Grid>

        <Grid item md={10}>
          {renderContent()}
        </Grid>
        {/* Drawer */}
        <RightDrawer
          open={openDrawer}
          onClose={setOpenDrawer}
          title={currentInventory}
        >
          {renderDrawerComponent()}
        </RightDrawer>
      </Grid>
      <Chatbot/>
    </div>
  );
};

export default InventoryManagement;
