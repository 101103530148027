import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import LinkOffIcon from "@material-ui/icons/LinkOff";
import SvgtoIcon from "utils/SvgtoIcon";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as deleteSvg } from "assets/criterion_networks/svg/delete.svg";
import { Alert } from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import ArchiveTable from "./ArchiveInformationTable";
import { useDispatch } from "react-redux";
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import { Api } from "config/api";
import LoaderHoc from "../../components/HOC/LoaderHoc";
import { saveAs } from "file-saver";
import "./accounts.css";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  alertStyle: {
    borderColor: theme.palette.buttonColor.main,
    color: theme.palette.buttonColor.main,
    "&:hover": {
      borderColor: theme.palette.buttonColor.main
    }
  }
}));

function Archivecluster(props) {
  // States
  const [rows, setRows] = useState([]);
  const [hideTable, setHideTable] = useState(true);
  const [tableOpacity, setTableOpacity] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [recordCount, setrecordCount] = useState(10);
  const [searchValue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [rowstemp, setrowstemp] = useState([]);

  const classes = useStyles();
  useEffect(() => {
    renderData(pageNo,recordCount,searchValue);
  }, [pageNo,recordCount,searchValue]);

  const columns = [
    {
      Header: "Template Name",
      accessor: "name",
      disableGroupBy: true
    },
    {
      Header: "Cluster Name",
      accessor: "template_name",
      disableGroupBy: true
    },
    {
      Header: "Username",
      accessor: "user",
      disableGroupBy: true
    },
    {
      Header: "Type",
      accessor: "type",
      disableGroupBy: true
    },
    {
      Header: "Archived At",
      accessor: "archived_at",
      disableGroupBy: true
    },
    {
      Header: "Time Remaining (in days)",
      accessor: "time_remaining",
      disableGroupBy: true
    },
    {
      Header: "Action",
      accessor: "action",
      disableGroupBy: true
    }
  ];



  const renderData = (page_no,record_count,search_value) => {
    let data = [];
    SDCloudBackendAPIWithToken()
      .get(
        `/get_archived_clusters?search=${search_value ??
          searchValue}&page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}`
      )
      .then(resp => {
        resp.data.cluster_info.forEach((item, index) => {
          data.push({
            uuid:item.uuid,
            name: item.name,
            template_name: item.template_name,
            type: item.type,
            user: item.user,
            archived_at: item.archived_at
            ? moment(item.archived_at).format("MM/DD/YYYY hh:mm a")
            : "",
            time_remaining:item.time_remaining>0?item.time_remaining:0,
            isUnarchiveDisabled: false,
            isTerminateDisabled: false,
          });
        });
        setRows(data);
        setrowstemp(resp.data);
        setHideTable(false);
        setIsLoading(false);
      })
      .catch(err => {
        let message = err.response.data.message;
        setRows([]);
        setIsLoading(false);
        setHideTable(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage(message);
      });
  };
  const unArchiveCluster=(uuid)=>{
    if (window.confirm("Are you sure you want to unarchive this cluster?")) {
      updateRowState(uuid, { isUnarchiveDisabled: true });
      let payload={};
      payload["cluster_uuid"]=uuid
      SDCloudBackendAPIWithToken()
        .post('/unarchive_cluster/', payload)
        .then(resp => resp.data)
        .then(data => {
          let message="Unarchive action initiated successfully"
          setShowNotification(true)
          setNotificationType("success")
          setNotificationMessage(message)
          setTableOpacity(1);
          renderData(pageNo,recordCount,searchValue)
        })
        .catch(err => {
          setTableOpacity(1);
          updateRowState(uuid, { isUnarchiveDisabled: false })
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    } else {
      updateRowState(uuid, { isUnarchiveDisabled: false })
    }
  }
  const terminateCluster=(uuid)=>{
    if (window.confirm("Are you sure you want to terminate this cluster?")) {
      updateRowState(uuid, { isTerminateDisabled: true });
      let payload={};
      payload["cluster_uuid"]=uuid
      SDCloudBackendAPIWithToken()
        .post('/terminate_archived_cluster/', payload)
        .then(resp => resp.data)
        .then(data => {
          let message="Terminate action initiated successfully"
          setShowNotification(true)
          setNotificationType("success")
          setNotificationMessage(message)
          setTableOpacity(1);
          renderData(pageNo,recordCount,searchValue)
        })
        .catch(err => {
          setTableOpacity(1);
          updateRowState(uuid, { isTerminateDisabled: false });
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    } else {
      updateRowState(uuid, { isTerminateDisabled: false });
    }
  }
  const updateRowState = (uuid, updates) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.uuid === uuid ? { ...row, ...updates } : row
      )
    );
  };
  const handleDataSearch = (search_value) => {
    setsearchvalue(search_value);
  };
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
  };

return (
  <div>
    <Grid container>
      <Grid
          item
          xs={12}
          sm={12}
          style={{ marginTop: "1%", opacity: tableOpacity }}
        >
        {showNotification ? (
          <Alert
            onClose={() => setShowNotification(false)}
            severity={notificationType}
          >
            {notificationMessage}
          </Alert>
        ) : null}
        <LoaderHoc isLoading={isLoading}>
          {hideTable ? null : (
            <ArchiveTable
              columns={columns}
              // data={rows}
              data={rows.map(row => ({
                ...row,
                action: (
                  <div>
                    <Grid container wrap="wrap" spacing={1}>
                      <Grid item>
                        <Tooltip title="Unarchive" placement="bottom-start">
                          <IconButton
                            onClick={() => unArchiveCluster(row.uuid)}
                            disabled={row.isUnarchiveDisabled}
                          >
                            {row.user ? <LinkOffIcon /> : <UnarchiveIcon />}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Terminate" placement="bottom">
                          <IconButton
                            onClick={() => terminateCluster(row.uuid)}
                            disabled={row.isTerminateDisabled}
                          >
                            {row.user ? <LinkOffIcon /> : <DeleteIcon />}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                ),
              }))}
              tableTitle="Archived Clusters"
              count={rowstemp?.pagination?.total_pages}
              page={rowstemp?.pagination?.current_page}
              setArchivedList={handlePagination}
              handleSearch={handleDataSearch}
            />
          )}
        </LoaderHoc>
      </Grid>
    </Grid>
  </div>
);
}
export default Archivecluster;
