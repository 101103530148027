import React,{ useEffect,useState } from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Pagination from "@material-ui/lab/Pagination";
// Other Components
import {
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { v4 as uuidv4 } from "uuid";
import TableHeader from "./TableHeader";

// Styling
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px",
  },
  pagesDropdown: {
    backgroundColor: "#ffffff",
    color: theme.palette.grey[700],
    border: "none",
    padding: "5px",
    "& .MuiOutlinedInput-input": {
      padding: "0 25px 0 0",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  paginationToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  paginationNumbers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p, div": {
      margin: "0 10px",
    },
  },
  numberButton: {
    minWidth: "0px",
    padding: "0px",
    "& .MuiButton-label": {
      width: "35px",
      height: "24px",
      backgroundColor: "#ffffff",
      color: theme.palette.grey["600"],
    },
    "& .MuiButton-text": {
      padding: "0 1px",
    },
  },
  table: {
    root: {
      "& .MuiTableCell-root": {
        borderBottom: "5px solid green",
      },
      "& .MuiTableCell-body": {
        color: "black",
      },
      "& ,MuiFormControl-root": {
        marginTop: "0",
      },
    },
  },
  previousButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  nextButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  active: {
    "& .MuiButton-label": {
      backgroundColor: "#3868C2",
      color: "#fff",
    },
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont,
  },
  tablehead: {
    // fontWeight: "bold !important",
    padding: "5px",
  },
  tablebody: {
    padding: "5px",
  },
  tablepagination: {
    "& .MuiPagination-ul": {
      "& li:first-child .MuiPaginationItem-page": {
        background: "unset",
        "& svg": {
          display: "none",
        },
        "&:after": {
          content: '"Previous"',
          fontFamily: "Roboto",
          fontWeight: 300,
          fontSize: "14px",
          color: "#F96B13",
          marginRight: "40px",
        },
      },
      "& li:last-child .MuiPaginationItem-page": {
        background: "unset",
        "& svg": {
          display: "none",
        },
        "&:after": {
          content: '"Next"',
          fontFamily: "Roboto",
          fontWeight: 300,
          fontSize: "14px",
          color: "#F96B13",
        },
      },
    },
    "& .MuiPaginationItem-page": {
      background: "#FFFFFF",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      color: "#0F1A2B",
      border: "0",
      borderRadius: "0",
      margin: "0",
      width: "24px",
      height: "24px",
      "&.Mui-selected": {
        backgroundColor: "#3868C2 !important",
        fontWeight: 300,
        fontSize: "14px",
        color: "#fff !important",
      },
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const CustomTable = ({
  columns,
  data,
  table,
  count,
  setList,
  handleSearch,
  screen,
  handleButtonClick,
  handleDeleteButtonClick,
  tableTitle,
  buttonTitle,
  dropDownValues,
  handleDropDownChange,
  hiddenColumns,
  showDropdown,
}) => {
  const classes = useStyles();
  const [pageNo,setpageNo] = React.useState(1)
  const [searchTerm, setSearchTerm] = useState("");
  const [currentScreen, setCurrentScreen] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    pageOptions,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const handleDeleteButton = () => {
    if (!window.confirm("Are you sure want to delete the selected ?")) {
      return false;
    }
    handleDeleteButtonClick(selectedRowIds, selectedFlatRows);
  };

  const handleButton = () => {
    handleButtonClick(selectedFlatRows);
  };
  const handlePage = (event, newPage) => {
    setList(newPage,pageSize);
    setpageNo(newPage);
    gotoPage(newPage - 1);
  };
  useEffect(() => {
    if (screen !== currentScreen) {
      setpageNo(1);
      setPageSize(10);
      setCurrentScreen(screen);
    }
  })
  const handleRecordCount = (event) => {
    const newPageSize = Number(event.target.value)
    setPageSize(newPageSize);
    gotoPage(1);
    setpageNo(1);
    setList(1, newPageSize);
  };
  const handleDataSearch = (event) => {
    if(event){
      setSearchTerm(event);
      handleSearch(event);
    } else {
      gotoPage(1);
      setpageNo(1);
      setList(1, pageSize);
      handleSearch('');
    }
  };
  
  return (
    <div>
      <Paper elevation={0}>
        <TableContainer className={classes.container}>
          <Typography variant="h4">{tableTitle}</Typography>

          <TableHeader
            handleButtonClick={handleButton}
            handleDeleteButtonClick={handleDeleteButton}
            buttonTitle={buttonTitle}
            dropDownValues={dropDownValues}
            handleDropDownChange={handleDropDownChange}
            showDropdown={showDropdown}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={searchTerm}
            setGlobalFilter={handleDataSearch}
            useAsyncDebounce={useAsyncDebounce}
          />

          <Table
            className={classes.table}
            aria-label="customized table"
            {...getTableProps()}
          >
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classes.tablehead}
                    >
                      {column.render("Header")}
                      
                      {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span>
                          <ArrowDropDownIcon />
                        </span>
                      ) : (
                        <span>
                          <ArrowDropUpIcon />
                        </span>
                      )
                    ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={classes.tablebody}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <div className={classes.paginationToolbar}>
                    <div>
                      <span>
                        <select
                          className={`display-page ${classes.font}`}
                          value={pageSize}
                          onChange={handleRecordCount}
                        >
                          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize} Items
                            </option>
                          ))}
                        </select>
                        &nbsp;&nbsp;per page
                      </span>
                    </div>
                    <Pagination
                      className={classes.tablepagination}
                      count={count}
                      page={pageNo} 
                      variant="outlined"
                      onChange={handlePage}
                      style={{paddingRight:"45px"}}
                    />
                  </div>
    </div>
  );
};

export default CustomTable;
