import React, { useEffect, useState } from "react";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Icons
import Visibility from "@material-ui/icons/Visibility";

// Local
import CustomTable from "./Table";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import RightDrawer from "./RightDrawer";
import AddUser from "./AddUser";
import UserDetails from "./UserDetails";
import { Add } from "@material-ui/icons";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "30px 20px",
  },
}));

const ManageUsers = () => {
  const classes = useStyles();
  // States
  const [rows, setRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [recordCount, setrecordCount] = useState(10);
  const [searchValue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [rowstemp, setrowstemp] = useState([]); 
  // useEffects
  useEffect(() => {
    renderData(pageNo,recordCount,searchValue);
  }, [pageNo,recordCount,searchValue]);

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Privilege",
      accessor: "privilege",
    },
    {
      Header: "Permission Profile",
      accessor: "profile",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];

  let data = [];
  const renderData = async (page_no,record_count,search_value) => {
    let response = SDCloudBackendAPIWithToken()
      .get(`/get_user?search=${search_value ??
          searchValue}&page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}`)
      .then((resp) => resp.data)
      .then((resp) => {
        resp.users.forEach((item) => {
          data.push({
            email: item.email,
            username: item.username,
            privilege: item.privilege,
            profile: item.permission_profile_name
              ? item.permission_profile_name
              : "None",
            actions: (
              <IconButton onClick={() => handleViewButton(item.username)}>
                <Visibility />
              </IconButton>
            ),
          });
        });

        setRows(data);
        setIsLoading(false);
        setrowstemp(resp);
      })
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });
  };

  const renderuser = () =>{
    renderData(pageNo,recordCount,searchValue)

  }

  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "Add User":
        return <AddUser resetapi={renderuser} />;
      case "User Details":
        return <UserDetails user={currentUser} />;
      case "default":
        return null;
    }
  };

  const handleAddUserButton = () => {
    setOpenDrawer(true);
    setCurrentScreen("Add User");
  };

  

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    let payload = {};
    selectedFlatRows.forEach((item) => {
      payload["username"] = item.values.username;
      SDCloudBackendAPIWithToken()
        .post("/disable_user", payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("User disabled sucessfully");
          renderData(pageNo,recordCount,searchValue);
        })
        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    });
  };

  const handleViewButton = (username) => {
    setOpenDrawer(true);
    setCurrentScreen("User Details");
    setCurrentUser(username);
  };
  const handleDataSearch = (search_value) => {
    setsearchvalue(search_value);
  };
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
            >
              {notificationMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              handleDeleteButtonClick={handleDeleteButton}
              handleButtonClick={handleAddUserButton}
              handleViewButtonClick={handleViewButton}
              tableTitle="Users"
              buttonTitle="Add User"
              dropDownValues={[]}
              count={rowstemp?.pagination?.total_pages}
              page={rowstemp?.pagination?.current_page}
              setList={handlePagination}
              handleSearch={handleDataSearch}
            />
          </LoaderHoc>
        </Grid>
      </Grid>

      {/* Drawer */}
      <RightDrawer
        open={openDrawer}
        onClose={setOpenDrawer}
        title={currentScreen}
      >
        {renderDrawerComponent()}
      </RightDrawer>
    </div>
  );
};

export default ManageUsers;